import React from 'react'
import imgSobre from "../Assets/site candidato/candidato/Home/Ft ilustração original.jpg";
function Sobre () {
  return (
    <div id='about'>
        <div className='about-image'>
            <img src={imgSobre} alt='' />
            <h1>Carlos Inacio</h1>
            <p>PD - PARTIDO DEV</p>
            <p>Vote 22013</p>
        </div>
        <div className='about-text'>
            <h1>Sobre Carlos Inacio</h1>
            <p>Na Vincc1 Tecnologia e Inovação, acreditamos que a tecnologia pode transformar o mundo e melhorar a vida das pessoas. Fundada com base em valores sólidos de ética, inovação e excelência, nossa missão é oferecer soluções tecnológicas que realmente façam a diferença.

Com uma equipe experiente, atuamos em várias frentes, incluindo consultoria de tecnologia, desenvolvimento de aplicativos e cursos de capacitação. Em cada novo projeto, buscamos inovar e explorar a criatividade ao máximo, inspirando-nos na genialidade de Leonardo da Vinci, que revolucionou o mundo com suas ideias visionárias e ousadas.

Nosso compromisso é com a qualidade e a satisfação do cliente, sempre adotando as melhores práticas e inovações para entregar resultados superiores.</p>
            
        </div>
    </div>
  )
}

export default Sobre
