import React from 'react'
import imgproject1 from "../Assets/site candidato/candidato/Projetos/Original Projeto 01.jpg";
function Contact () {
  return (
    <div id="presentaion">
         
        <main>
        <h1>GALERIA</h1>
        <div class="gallery-container">
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Chick Hicks" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Doctor Hudson" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Fillmore" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Flo" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Guido" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="lightning-mcqueen" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Lizzie" />
            </a>
            <a href={imgproject1} class="gallery-items">
                <img src={imgproject1} alt="Luigi" />
            </a>
            
            
        </div>        
    </main>

        

    </div>
  )
}
export default Contact