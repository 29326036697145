import React, { useState, useEffect, useRef, useCallback } from "react";
import fundo1 from "../Assets/foto_candidato.jpeg";
import fundo2 from "../Assets/foto_candidato.jpeg";


function Home() {
  const [count, setCount] = useState(1);
  const totalImages = 2;
  const intervalId = useRef(null);

  const nextImage = useCallback(() => {
    setCount((prevCount) => (prevCount >= totalImages ? 1 : prevCount + 1));
  }, [totalImages]);

  const startAutoSlide = useCallback(() => {
    intervalId.current = setInterval(() => {
      nextImage();
    }, 10000);
  }, [nextImage]);

  useEffect(() => {
    startAutoSlide();
    return () => clearInterval(intervalId.current);
  }, [startAutoSlide]);

  const manualChange = (index) => {
    clearInterval(intervalId.current);
    setCount(index);
    startAutoSlide();
  };

  return (
    <div id="main">
      <div className="name">
        <div className="candidato">
        <h2>CANDIDATO </h2>
        <h1><span>VEREADOR</span></h1>
        
          
        
        <p className="details">Carlos Inácio, candidato a vereador pelo PD (Vote 22013) no
Maranhão, propõe o "Programa de Inclusão Digital" para garantir
acesso à internet e cursos de informática, preparando os jovens
para o futuro.</p>
</div>
<div class="contForm">
  <div className="left">
        <form class="support-form">
            <div class="input-group">
                <label for="nome-completo">Nome completo</label>
                <input type="text" id="nome-completo" name="nome-completo" />
            </div>
            <div class="input-group">
                <label for="email">E-mail</label>
                <input type="email" id="email" name="email" />
            </div>
            <div class="input-group-full">
                <label for="mensagem">Digite sua mensagem de apoio</label>
                <textarea id="mensagem" name="mensagem"></textarea>
            </div>
            <button type="submit">Enviar</button>
        </form>
        </div>
        </div>

      </div>
      <div className="slider">
        <div className="slides">
          <input type="radio" name="radio-btn" id="radio1" checked={count === 1} readOnly />
          <input type="radio" name="radio-btn" id="radio2" checked={count === 2} readOnly />
          

          <div className="slide first">
            <img src={fundo1} alt="s" />
          </div>
          <div className="slide">
            <img src={fundo2} alt="s" />
          </div>
          

          <div className="navigation-auto">
            <div className="auto-btn1"></div>
            <div className="auto-btn2"></div>
            
          </div>
        </div>
      </div>

      <div className="manual-navigation">
        <label htmlFor="radio1" className="manual-btn" onClick={() => manualChange(1)}></label>
        <label htmlFor="radio2" className="manual-btn" onClick={() => manualChange(2)}></label>
        
      </div>
    </div>
  );
}

export default Home;
