import React from 'react'
import imgproject1 from "../Assets/site candidato/candidato/Projetos/Original Projeto 01.jpg";
function Apoio () {
  return (
    <div id="presentaion2">
       <div className='containerForm'>
  
  <div className='right'>
  <div className='title'><h1>Confirme seu Apoio</h1></div>
    <div className='formBox'>
     
      <form action='https://formsubmit.co/' method='POST'>
        <p>Digite seu nome</p>
        <input type="text" name="Nome" placeholder="Digite seu nome.." required></input>
        <p>Digite seu email</p>
        <input type="email" name="Email" placeholder="Digite seu email.." required></input>
        
        <p>Escreva sua mensagem</p>
        <input type="text" name="Mensagem" placeholder="Digite sua mensagem..." required></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value=""></input>
        <input type="submit" name="" value="Enviar"></input>
       
      </form>
    </div>
  </div>


</div>


        

    </div>
  )
}
export default Apoio