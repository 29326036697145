import React from 'react';
import Projects from './Projects';
import imgproject1 from "../Assets/Original Projeto 01.jpg";
import imgproject2 from "../Assets/Original Projeto 03.jpg";
import imgproject3 from "../Assets/Original Projeto 03.jpg";
import imgproject4 from "../Assets/Original Projeto 04.jpg";
import imgproject5 from "../Assets/Original Projeto 03.jpg";
import imgproject6 from "../Assets/Original Projeto 01.jpg";

function Work() {
  return (
    <div id='features'>
        <h1>PROJETOS</h1>
        <div className='a-container'>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject1} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject2} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject3} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject4} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject5} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Projects image={imgproject6} title="Titulo" subtitle="Flibberty floo, the snooglefrap danced on
a wobbly wimble, while the twizzlehorn
snorfled in the glimmering zoodle." />
            </a>
        </div>
    </div>
  )
}

export default Work